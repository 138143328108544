@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat SemiBold';
  src: local('Montserrat SemiBold'),
    url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Wallop';
  src: local('Wallop'), url(./fonts/Wallop-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Wallop Bold';
  src: local('Wallop Bold'), url(./fonts/Wallop-Bold.otf) format('opentype');
}
body {
  margin: 0;
}
